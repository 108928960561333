<template>
  <div>
    <n-c-event
      :group-category="2"
      :group-nc="1"
    />
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import NCEvent from '@/views/new-content-manager/pages/Event/NCEvent.vue'

export default {
  components: {
    NCEvent,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted() {
  },
  validations: {
  },
  methods: {
  },
}
</script>
